.App {
    margin-top: 40px;
    margin-bottom: 108px;
    font-size: 10.0pt;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 8.0pt;
    color: white;
}

.App-link {
    color: #09d3ac;
}