.header {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 100%;
    border-radius: 0px 0px 0px 25px;
    color: white;
    background-color: #454545;
    padding-left: 15px;
    padding-right: 10px;
    padding-bottom: 5px;
    text-align: right;
    font-size: 16.0pt;
    z-index: 10;
}