.title {
    text-transform: uppercase;
    font-size: small;
    font-weight: bold;
    color: #A5A5A5;
}

.AuthCard {
    border: #656565;
}

.AuthCard:hover {
    color: #454545;
    background-color: rgba(200, 200, 200, 0.50);
    cursor: pointer;
}

.sub_txt {
    font-size: 8.0pt;
    color: rgba(180, 180, 180, 1);
}