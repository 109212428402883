.menuBarD {
    position: fixed;
    top: 0;
    bottom: 38px;
    max-width: 185px;
    color: #FFFFFF;
    background-color: #454545;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16.0pt;
    z-index: 9;
}

.menuBarM {
    position: fixed;
    right: 0;
    bottom: 38px;
    width: 100%;
    color: #FFFFFF;
    background-color: #454545;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 5px;
    font-size: 8.0pt;
    overflow-x: auto;
    z-index: 9;
}

.text-overflow {
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 26.0pt;
    text-indent: -26.0pt;
    vertical-align: top;
}

.setCursor {
    cursor: pointer;
    color: #FAFAFA;
}

.setCursor:hover {
    /* color: rgb(60, 180, 255, 255); */
    color: rgb(255, 255, 255, 255);
    text-decoration: none;
}