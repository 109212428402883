a {
    color: rgb(200, 200, 200, 255);
}

a:hover {
    /* color: rgb(60, 180, 255, 255); */
    color: rgb(255, 255, 255, 255);
    text-decoration: none;
}

a:active {
    color: rgb(0, 0, 0, 255);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.row {
    padding-left: 7px;
    padding-right: 7px;
}

.col {
    padding-left: 7px;
    padding-right: 7px;
}

.col-1 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-2 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-3 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-4 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-5 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-6 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-7 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-8 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-9 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-10 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-11 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-12 {
    padding-left: 7px;
    padding-right: 7px;
}
.col-lg-1 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-2 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-3 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-4 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-5 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-6 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-7 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-8 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-9 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-10 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-11 {
    padding-left: 7px;
    padding-right: 7px;
}

.col-lg-12 {
    padding-left: 7px;
    padding-right: 7px;
}

.list-group-item {
    padding-left: 7px;
    padding-right: 7px;
}
.container {
    padding-left: 7px;
    padding-right: 7px;
}

.container-fluid {
    padding-left: 7px;
    padding-right: 7px;
}